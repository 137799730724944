<template>
  <div class="feed-back">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getList"
      class="lists"
    >
      <van-cell-group>
        <van-cell
          :title="`【${item.xzqh}】${item.adviceTitle}`"
          :label="item.adviceTime.slice(0.1)"
          title-class="cell-title"
          label-class="label-title"
          v-for="(item, index) in tableList"
          :key="index"
        >
          <template #default>
            <div class="btn no" v-if="item.replyState">未回复</div>
            <div class="btn yes" v-else>已回复</div>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      tableList: [],
      pageNo: 1,
    }
  },
  methods: {
    getList() {
      this.$axios({
        apiUrl: '/onekey/advice/select/advice/cinema',
        url: 'mgop.wasu.zyt.onekey0advice0select0advice0cinema',
        method: 'GET',
        data: {
          pageNo: this.pageNo,
          pageNum: 10,
          cinemaIds: this.$store.state.zlbUserId,
        },
      }).then((res) => {
        res.result.records.forEach((item) => {
          this.tableList.push(item)
        })
        // 加载状态结束
        this.loading = false
        //页数+1
        this.pageNo++
        // 数据全部加载完成
        if (this.tableList.length >= res.result.total) {
          this.finished = true
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.feed-back {
  width: 100%;
  //title样式
  .cell-title {
    font-size: 16px;
    font-weight: 500;
    color: #363a44;
    flex: 0 0 80%;
  }
  //label样式
  .label-title {
    font-size: 15px;
    font-weight: 400;
  }
  .btn {
    width: 70px;
    height: 24px;
    border-radius: 12px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }
  .no {
    color: #244e8a;
    background: #ebf3fe;
  }
  .yes {
    background: #cffcff;

    color: #0cbce6;
  }
}
</style>
